import React from 'react'
import * as apiClient from '../utils/apiClient'

const RatesContext = React.createContext({
    rates: {},
    error: null,
    loading: false
});

function RatesProvider({ssr = false, children}) {

    const [rates, setRates] = React.useState({})
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if(ssr)
            return
        setLoading(true)
        apiClient.client('rates')
            .then(rates => setRates(rates))
            .catch(error => setError(error.message))
            .then(() => setLoading(false))
    }, []) // eslint-disable-line

    return (
        <RatesContext.Provider
            value={{
                rates,
                error,
                loading
            }}>
            {children}
        </RatesContext.Provider>
    )
}

function useRates() {
    const context = React.useContext(RatesContext);

    if (context === undefined) {
        throw new Error(`useRates must be used within a RatesContext`)
    }

    return context
}

export {RatesProvider, useRates}
