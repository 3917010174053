const handleError = async (response) => {
    const error = await response.json();
    throw new Error(response.status === 401 ? response.statusText : error.error.message);
};

export async function client(endpoint) {

    const url = `${process.env.GATSBY_API_URL}/${endpoint}`;

    const config = {
        method: "GET",
        headers: {
            "content-type": "application/json"
        },
    };

    const response = await fetch(url, config);

    if (!response.ok) {
        await handleError(response);
    }

    return response.json();
}
